import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { makeStyles, useTheme } from "@material-ui/styles";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { deepOrange, deepPurple } from "@material-ui/core/colors";
// import LocalDiningIcon from '@material-ui/icons/LocalDining';

import Divider from "@material-ui/core/Divider";

import Lightbox from "react-awesome-lightbox";
import InfoIcon from "@material-ui/icons/Info";
import IconButton from "@material-ui/core/IconButton";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Popover from "@material-ui/core/Popover";
import "react-lazy-load-image-component/src/effects/blur.css";

// import { useTheme } from '@material-ui/core/styles';

import Chip from "@material-ui/core/Chip";

import { useSelector, useDispatch } from "react-redux";
import { addDish } from "store/actions/dish";
import { updateDishPosition } from "store/actions/order";
import DetailsIcon from '@material-ui/icons/Details';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    margin: theme.spacing(1),
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 16,
  },
  price: {
    fontSize: 20,
    fontWeight: "bold",
  },
  chipSize: {
    fontSize: 10,
    marginLeft: 8,
  },
  pos: {
    marginBottom: 12,
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
  editText: {
    fontSize: 12,
  },
  buttonMargin: {
    marginLeft: 10,
    marginRight: 10,
  },
  buttonPadding: {
    padding: "10px 30px 10px 30px",
  },
  dishImage: {
    width: 100,
    height: 100,
    borderWidth: 0,
    borderRadius: 10,
  },
  dishImage2: {
    width: 138,
    height: 138,
    borderWidth: 0,
    borderRadius: 12,
  },
  fab: {
    right: theme.spacing(4),
  },
  spacer: {
    flexGrow: 1,
  },
  typography: {
    padding: theme.spacing(2),
  },
  divider: {
    width: "90%",
    marginRight: theme.spacing(1),
  },
}));

const Dish = (props) => {
  const {  item } = props;
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const [imageVisible, setImageVisible] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const restaurant = useSelector((state) => state.menu.restaurant);
  const menuTableId = useSelector((state) => state.menu.tableId);
  const dispatch = useDispatch();

  const showDish = (dish) => dispatch(addDish(dish));
  const updatePosition = (position) => dispatch(updateDishPosition(position));
  // const setCategory = (idx) => dispatch(selectedCategory(idx));
  // const selectedCategoryIdx = useSelector(
  //   (state) => state.menu.selectedCategoryIdx
  // );

  // const [isRequiredOptionsChecked, setIsRequiredOptionsChecked] = useState(
  //   false
  // );

  const showDishDetail = () => {
    updatePosition(item.id);
    showDish({ ...item });

    history.push("/dishDetail");
  };



  // a function to compare trimmed item name and trimmed altname
  const compareName = (name, altname) => {
    if (!name || !altname) {
      return false;
    }
    return name.trim().toLowerCase() === altname.trim().toLowerCase();
  };


  const priceString = () => {
    let priceStr = `${restaurant && restaurant.currencySymbol}${props.item.price}`;
    // if (props.item.sizes && props.item.sizes.length > 0) {
    //    const priceList = props.item.sizes.map(size => Number(size.price));
    //     const minimumPrice = Math.min(...priceList);
    //     priceStr = `From $${minimumPrice}`
    // }

    return priceStr;
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const tagText = (tag) => {
    if (tag === "Spicy") {
      return "🌶️";
    }
    if (tag === "Hotter") {
      return "🌶️🌶️";
    }
    if (tag === "Hottest") {
      return "🌶️🌶️🌶️";
    }

    return tag;
  };

  const isBigImage = () => {
    return restaurant.isBigMenuImageUsed === true;
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <React.Fragment>
      <div id={item.id}></div>
      {/* <Card className={classes.root} variant="outlined">
                <CardContent> */}
      <Grid container direction="column" className={classes.root} spacing={1}>
        <Grid item container direction="row" spacing={1} xs={12}>
          <Grid item container xs={item.image ? (isBigImage() ? 5 : 4) : 1}>
            {item.image ? (
              <img
                alt={item.name}
            
                src={item.image}
                className={isBigImage() ? classes.dishImage2 : classes.dishImage}
                onClick={() => {
                  setImageVisible(true);
                }}
              />
            ) : // <img
            //   src={getThumbnail()}
            //   alt="image"
            //   className={isBigImage() ? classes.dishImage2 : classes.dishImage}
            //   onClick={() => {
            //     setImageVisible(true);
            //   }}
            // />
            null}
          </Grid>
          <Grid item xs={item.image ? (isBigImage() ? 7 : 8) : 12} container direction="column">
            <Typography className={classes.title}>{item.name}</Typography>
            <Typography className={classes.title} gutterBottom>
              {compareName(item.altName, item.name) ? "" : item.altName}
            </Typography>
            <Grid item container direction="row">
              <Typography className={classes.price} color="textSecondary">
                {priceString()}
                {props.item.description && props.item.description.trim() !== "" ? (
                  <>
                    <IconButton aria-label="info" color="primary" onClick={handleClick}>
                      <InfoIcon fontSize="small" />
                    </IconButton>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <Typography className={classes.typography}>{props.item.description}</Typography>
                    </Popover>
                  </>
                ) : null}

                {item.tags && item.tags.find((tag) => tag === "Recommended") ? (
                  <ThumbUpIcon
                    fontSize="small"
                    style={{
                      marginLeft: theme.spacing(1),
                      marginBottom: -4,
                      color: "#fccc14",
                    }}
                  />
                ) : null}
              </Typography>
              <span className={classes.spacer} />
           
              <Fab color="primary" aria-label="add" className={classes.fab} size="small" onClick={showDishDetail}>
              {menuTableId === 'onlineMenu' ? (
                <DetailsIcon />
              ) : ( <>
                <AddIcon />
                </>
              )}
              </Fab>
             
            </Grid>
          </Grid>
        </Grid>

        <Grid item container direction="row">
          <Grid item xs={item.image ? 4 : 1}></Grid>

          <Grid item>
            {props.item.tags &&
              props.item.tags
                .filter((tag) => tag !== "Recommended")
                .map((tag) => {
                  return <Chip className={classes.chipSize} size="small" label={tagText(tag)} key={tag} />;
                })}
          </Grid>
        </Grid>

        {item.image && imageVisible ? (
          <Lightbox
            image={[item.image ]}
            allowZoom={false}
            allowRotate={false}
            onClose={() => {
              setImageVisible(false);
            }}
          />
        ) : null}

        <Divider className={classes.divider} />
      </Grid>
    </React.Fragment>
  );
};

export default Dish;
