import React, { Fragment, useState, useEffect } from "react";
import { useScrollSections } from "react-scroll-section";

import { fade, makeStyles } from '@material-ui/core/styles';
// import './cart.css';

import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";

import Toolbar from "@material-ui/core/Toolbar";

import { useSelector, useDispatch } from "react-redux";

import { selectedCategory } from "store/actions/menu";
import Grid from "@material-ui/core/Grid";
import { NavLink } from "react-router-dom";
import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import InputBase from '@material-ui/core/InputBase';
import InputAdornment from "@material-ui/core/InputAdornment";

import CallButton from "./CallButton";

import { loadTableId, isDineInTable, decodeRestaurantId } from "util/utils";
import { updateSearch } from 'store/actions/menu';



function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  bar: {
    position: "fixed",
    zIndex: 1,
    left: "50%",
    transform: "translateX(-50%)",
    display: "table",
    marginLeft: "auto",
    marginRight: "auto",
    margin: 0,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
    marginLeft: "auto",
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    marginLeft: "auto",
  },

  uiProgess: {
    position: "fixed",
    zIndex: "1000",
    height: "16px",
    width: "16px",
    left: "50%",
    top: "50%",
  },
  toolbar: theme.mixins.toolbar,
  toolbarButtons: {
    marginLeft: "auto",
  },
  tabs: {
    backgroundColor: "rgba(255, 255, 255, 0.7)",
  },
  tabLabel: {
    fontSize: 12,
    fontWeight: "bold",
  },

  imageIcon: {
    width: "25px",
    height: undefined,
  },
  cartIcon: {
    width: "25px",
    height: undefined,
  },
  iconRoot: {
    textAlign: "center",
  },
  bell: {
    flexGrow: 1,
    alignItems: "center",
  },
  textLink: {
    textDecoration: 'none',
    color: 'inherit',
  },
  
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.55),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.55),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  cartAnimation: {
    animation: `$cartAnimation 2s ${theme.transitions.easing.easeInOut}`,
    // composes: 'cart.animate',
  },
}));

const DynamicCategories = (props) => {
  const dispatch = useDispatch();
  const { categories, tableIdDisplay, menuId, tableId, ordersSummary, restaurantName } = props;
  const classes = useStyles();
  const sections = useScrollSections();
  const selectedCategoryIdx = useSelector(
    (state) => state.menu.selectedCategoryIdx
  );

  const searchItem = (query) => dispatch(updateSearch(query));

  const [search, setSearch] = useState('');
  const setCategory = (idx) => dispatch(selectedCategory(idx));
  const order = useSelector((state) => state.order);
  // const [cartAnimation, setCartAnimation] = useState(false);
  //console.log('ordersSummary', ordersSummary);

  const cartLinkTarget = `/cart/${menuId}/${tableId}`;

  const chooseCategory = (e, onClick, index) => {
    setCategory(index);
    onClick(e);
  };


  const getImgNameFromType = () => {
    const type = getOrderType();
    if (type.startsWith("Table")) {
      return "table";
    } else {
      return type;
    }
  };

  const getOrderType = () => {
    let orderType = tableIdDisplay;
    if (!orderType && decodeRestaurantId(menuId)) {
      orderType = loadTableId(decodeRestaurantId(menuId));
    }
    if (isDineInTable(orderType)) {
      return `Table-${orderType && orderType.split(";")[0]}`;
    } else {
      return orderType;
    }
  };

  const cartItemCount = order.cart.reduce((count, curItem) => {
    return count + curItem.quantity;
  }, 0);

  const searchUpdate = (event) => {
    setSearch(event.target.value);
    searchItem(event.target.value);
  };

  const clearSearch = () => {
    setSearch('');
    searchItem('');
  }

  const handleMouseDown = event => {
    event.preventDefault();
  };

  const endAdornment = () => {
    if (search) {
      return (
        <InputAdornment position="end">
          <IconButton
            onClick={clearSearch}
            onMouseDown={handleMouseDown}
            color="secondary"
          >
            <ClearIcon />
          </IconButton>
        </InputAdornment>
      );
    }

    return "";
  };

  return (
    <Fragment>
      <AppBar position="sticky" id="header">
        <Toolbar>
        {ordersSummary && ordersSummary.length > 0 ? 
              <NavLink to={`/orderSuccessDetail/${menuId}/${tableId}/abc}`} className={classes.textLink}>
               My Orders
              </NavLink>
              : null}
          <img
            className={classes.imageIcon}
            alt={getImgNameFromType()}
            src={`/images/icons/${getImgNameFromType()}.png`}
          />
  {tableId !== 'onlineMenu' ? ( <>
          <Typography variant="h6" style={{ marginLeft: 8 }}>
            {getOrderType().startsWith("Table")
              ? getOrderType().split("-")[1]
              : ""}
          </Typography>
          <Grid
            alignItems="center"
          
            container
            justify="center"
          >
          
            <Grid item>
              <CallButton tableId={tableIdDisplay} />
            </Grid>
          
          </Grid>
       
          <div className={classes.sectionDesktop}>
            <NavLink to={cartLinkTarget} >
              <IconButton
              
                aria-label="show cart item number"
                color="inherit"
                className={classes.toolbarButtons}
              >
                
                <Badge
              
                  badgeContent={cartItemCount.toString()}
                  color="secondary"
                >
                  <img className={classes.cartIcon} src="/images/cart_icon.png" />
                </Badge>
              </IconButton>
            </NavLink>
          </div>
          <div className={classes.sectionMobile}>
            <NavLink to={cartLinkTarget}>
              <IconButton aria-label="show cart item number" color="inherit">
                <Badge
                  badgeContent={cartItemCount.toString()}
                  color="secondary"
                >
                  <img src="/images/cart_icon.png" />
                </Badge>
              </IconButton>
            </NavLink>
          </div></>) : (
              <Grid
            alignItems="center"
            container
            justify="center"
          >
            <Typography variant="h6">{restaurantName}</Typography>
          </Grid>
          )}
        </Toolbar>
        <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
            value = {search}
              placeholder="Search…"
              onChange={e =>searchUpdate(e)}
              endAdornment={endAdornment()}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
           
          </div>
          {search.length === 0 ?
        <Paper className={classes.grow}>
          <Tabs
            className={classes.tabs}
            value={selectedCategoryIdx}
            // onChange={handleChange}
            variant="scrollable"
            indicatorColor="primary"
          
            scrollButtons="auto"
            aria-label="scrollable auto tabs category"
          >
            {sections.map(({ id, onClick, selected }, index) => {
              if (selected) {
                setCategory(index);
              }
              return (
                <Tab
                  key={id}
                  onClick={(e) => chooseCategory(e, onClick, index)}
                  label={
                    <span>
                      <Typography className={classes.tabLabel}>
                        {categories[index].name}
                      </Typography>
                      <Typography className={classes.tabLabel}>
                        {categories[index].altName}
                      </Typography>
                    </span>
                  }
                  {...a11yProps(index)}
                
                />
              );
            })}
          </Tabs>
        </Paper> : null}
      </AppBar>
    </Fragment>
  );
};

export default DynamicCategories;
