import API from 'util/API';
import { loadMenu, saveMenu } from "util/menuUtils";


export const getMenu = async (restaurantId, tableId, processMenuData) => {
  // console.log("getMenu", restaurantId, tableId);

  if(!restaurantId || restaurantId === "null" || restaurantId === "undefined" || !tableId || tableId === "null" || tableId === "undefined") {
    throw new Error("Invalid restaurantId or tableId");
  }

  const menu = loadMenu(restaurantId, tableId);

  if(menu && menu.version && menu.menuId) {
    API.defaults.headers.common["menuVersion"] = menu.version;
    API.defaults.headers.common["menuId"] = menu.menuId;
  }

  const response = await API.get("/online?menuId=" + restaurantId + "&tableId=" + tableId);
  if (response && response.data && response.data) {
    if (response.data.isSameVersion === true && menu) {
       processMenuData && processMenuData({...response.data, menu});
    } else {
      saveMenu(tableId, restaurantId, response.data.menu);
    }
    processMenuData && processMenuData({...response.data});
  }
  return null;
};

export const generateMenuCode = async (restaurantId, menuId, tableNumber, orderType, isPaymentRequired, isAllowOrder) => {
  const response = await API.post("/generateMenuCode", {restaurantId, menuId, tableNumber, orderType, isPaymentRequired, isAllowOrder});
  return response.data;
};

export const getAllMenus = async (restaurantId) => {
  const response = await API.get("/myMenus");
  console.log("getAllMenus", response.data);
  return response.data && response.data.menus ? response.data.menus : [];
};