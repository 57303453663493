import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';

import { MenusToolbar, MenusTable } from './components';

import CircularProgress from '@material-ui/core/CircularProgress';

import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getMyMenus } from 'store/actions/restaurantMenu';



const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	}
}));


const MenuList = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  //const [uiLoading, setUiLoading] = useState(false);
  
  //const [menus, setMenus] = useState([]);
  const history = useHistory();

  const user = useSelector((state) => state.account.user);
  const menus = useSelector((state) => state.restaurantMenu.menus);
  const uiLoading = useSelector((state) => state.restaurantMenu.isLoading);
  const [selectedMenus, setSelectedMenus] = useState([]);
  const loadMenus = () => dispatch(getMyMenus());
 // authAdminMiddleWare(history, user);

  useEffect(() => {

    // authMiddleWare(history);
    loadMenus();

  }, [props]);



  if (uiLoading === true) {
    return (
      <main className={classes.content}>
        {uiLoading && <CircularProgress size={150} className={classes.uiProgess} />}
      </main>
    );
  } else {

  return (
    <div className={classes.root}>
      <MenusToolbar selectedMenus={selectedMenus} updateSelectedMenu={setSelectedMenus}/>
      <div className={classes.content}>
        <MenusTable menus={menus ? menus : []} updateSelectedMenu={setSelectedMenus}/>
      </div>
    </div>
  );
  }
};

export default MenuList;
